import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#99151C', // #E53935
        secondary: '#000000', // '#FFCF00', // #FFCDD2
        accent: '#99151C',
        black: '#000000',
        yellow: '#E8C21E'
      },
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    },
});
