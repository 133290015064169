import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const users = () => import('../components/users');
const calendar = () => import('../components/calendar');
const crm = () => import('../components/crm/crm');
const verwaltung = () => import('../components/verwaltung');
import store from '@/store'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  { name: 'CRM', path: '/', component: crm, icon: 'mdi-home', show: true, meta: { requiresAuth: true } },
  { name: 'Kalender', path: '/kalender', component: calendar, icon: 'mdi-calendar-today', show: true, meta: { requiresAuth: true } },
  { name: 'Inhalte Verwalten', path: '/inhalte-verwalten', component: verwaltung, icon: 'mdi-settings', show: true, meta: { requiresAuth: true } },
  //{ name: 'Benutzer hinzufügen', path: '/users', component: users, display: 'Hinzufügen', icon: 'add', show: false, meta: { requiresAuth: true } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // TODO: auth should be replaced and rebuild: see ECRM-5
  const host = location.host
  let loginUrl = `https://${host}/account/login`;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      axios.get('/api/users/GetUser').then(async (response) => {
        const user = response.data;
        if (user != null && user !== '')  {
          await store.dispatch('setProfile', response.data);
          next();
        } else {
          window.location = loginUrl;
        }
      }).catch(error => {  console.log(error); window.location = loginUrl; } );
    } else {
      next();
    }
  } else {
    if (to.path !== loginUrl) {
      //not found
      let resolved = router.resolve(to.path);
      if (resolved.resolved.matched.length <= 0) {
        window.location = loginUrl;
      }
    }

    next();
  }
})

export default router
