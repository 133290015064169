import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

// TYPES
const MAIN_SET_PROFILE = 'MAIN_SET_PROFILE';

const SET_AKTIONEN = 'SET_AKTIONEN';
const SET_FILTERED_AKTIONEN = 'SET_FILTERED_AKTIONEN';

const SET_PERSON = 'SET_PERSON';
const SET_PERSONEN = 'SET_PERSONEN';
const SET_FILTERED_PERSONEN = 'SET_FILTERED_PERSONEN';

const SET_FIRMEN = 'SET_FIRMEN';
const SET_FILTERED_FIRMEN = 'SET_FILTERED_FIRMEN';

const SET_PRAESENTE = 'SET_PRAESENTE';
const SET_LOADING = 'SET_LOADING';
const SET_PAPIERKORB = 'SET_PAPIERKORB';
const SET_FILTERED_PAPIERKORB = 'SET_FILTERED_PAPIERKORB';
const UPDATE_PERSON = 'UPDATE_PERSON'
const UPDATE_FIRMA = 'UPDATE_FIRMA'
const DELETE_FIRMA = 'DELETE_FIRMA'
const SET_FIRMEN_PAPIERKORB = 'SET_FIRMEN_PAPIERKORB'
const DELETE_FIRMA_PAPIERKORB = 'DELETE_FIRMA_PAPIERKORB'
const ADD_FIRMA_PAPIERKORB = 'ADD_FIRMA_PAPIERKORB'
const ADD_FIRMEN = 'ADD_FIRMEN'
const ADD_PERSON_PAPIERKORB = 'ADD_PERSON_PAPIERKORB'
const REMOVE_PERSON = 'REMOVE_PERSON'
const DELETE_PERSONEN_PAPIERKORB = 'DELETE_PERSONEN_PAPIERKORB'
const ADD_PERSONEN = 'ADD_PERSONEN'

// STATE
const state = {
  profile: null,
  aktionen: [],
  filteredAktionen: [],
  person: null,
  personen: [],
  filteredPersonen: [],
  praesente: [],
  loading: true,
  firmen: [],
  filteredFirmen: [],
  papierkorb:[],
  filteredPapierkorb:[],
  firmenPapierkorb:[]
}

// MUTATIONS
const mutations = {
  [MAIN_SET_PROFILE](state, obj) {
    state.profile = obj;
  },
  [SET_AKTIONEN](state, obj) {
    state.aktionen = obj.data;
  },
  [SET_PAPIERKORB](state, obj) {
    state.papierkorb = obj.data;
  },
  [SET_PRAESENTE](state, obj) {
    state.praesente = obj.data;
  },
  [SET_FILTERED_AKTIONEN](state, obj) {
    state.filteredAktionen = obj.data;
  },
  [SET_PERSON](state, obj) {
    state.person = obj.data;
  },
  [DELETE_FIRMA](state, firmaId) {
    const firma = state.firmen.find(f => f.id === firmaId);
    const index = state.firmen.indexOf(firma);
    state.firmen.splice(index, 1);
  },
  [UPDATE_PERSON](state, person) {
    const oldPerson = state.personen.find(p => p.id === person.id);

    oldPerson.anrede = person.anrede;
    oldPerson.email = person.email;
    oldPerson.kontaktVon = person.kontaktVon;
    oldPerson.nachname = person.nachname;
    oldPerson.vorname = person.vorname;
    oldPerson.titel = person.titel;
    oldPerson.telefon = person.telefon;
    oldPerson.ort = person.ort;

    if(person.firma){
      oldPerson.firma = person.firma.name;
    }
  },
  [UPDATE_FIRMA](state, firma) {
    const oldFirma = state.firmen.find(p => p.id === firma.id);

    oldFirma.bemerkung = firma.bemerkung;
    oldFirma.kategorie = firma.kategorie;
    oldFirma.land = firma.land;
    oldFirma.namenZusatz = firma.namenZusatz;
    oldFirma.name = firma.name;
    oldFirma.telefon = firma.telefon;
    oldFirma.ort = firma.ort;
    oldFirma.plz = firma.plz;
    oldFirma.straße = firma.straße;
    oldFirma.additionalAddressInformation = firma.additionalAddressInformation;

  },
  [SET_PERSONEN](state, obj) {
    state.personen = obj.data;
  },
  [SET_FIRMEN](state, obj) {
    state.firmen = obj.data;
  },
  [SET_FIRMEN_PAPIERKORB](state, obj) {
    state.firmenPapierkorb = obj;
  },
  [ADD_PERSON_PAPIERKORB](state, person) {
    state.papierkorb.push(person);
  },
  [REMOVE_PERSON](state, person) {
    const listPerson = state.personen.find(p => p.id === person.id);
    const index = state.personen.indexOf(listPerson)
    state.personen.splice(index, 1);
  },
  [DELETE_FIRMA_PAPIERKORB](state, firmen) {

    for(let i = 0; i < firmen.length; i++){
      let firma = firmen[i];
      const entry = state.firmenPapierkorb.find(f => f.id === firma.id);
      const index = state.firmenPapierkorb.indexOf(entry);
      state.firmenPapierkorb.splice(index, 1);
    }

  },
  [DELETE_PERSONEN_PAPIERKORB](state, personen) {
    for(let i = 0; i < personen.length; i++){
      let person = personen[i];
      const entry = state.papierkorb.find(f => f.id === person.id);
      const index = state.papierkorb.indexOf(entry);
      state.papierkorb.splice(index, 1);
    }
  },
  [ADD_FIRMEN](state, firmen) {
    for(let i = 0; i < firmen.length; i++){
      state.firmen.push(firmen[i]);
    }
  },
  [ADD_FIRMA_PAPIERKORB](state, firma) {
  state.firmenPapierkorb.push(firma);
  },
  [ADD_PERSONEN](state, personen) {
    for(let i = 0; i < personen.length; i++){
      state.personen.push(personen[i]);
    }
  },
  [SET_FILTERED_FIRMEN](state, obj) {
    state.filteredFirmen = obj.data;
  },
  [SET_FILTERED_PERSONEN](state, obj) {
    state.filteredPersonen = obj.data;
  },
  [SET_FILTERED_PAPIERKORB](state, obj) {
    state.filteredPapierkorb = obj.data;
  },
  [SET_LOADING](state) {
    state.loading = false;
  }
}
const getters = ({
  isProfile: state => state.profile,
  isAuthenticated: state => state.profile != null
});

// ACTIONS
const actions = ({
  async setProfile({ commit }, profile) {
    commit(MAIN_SET_PROFILE, profile);
  },
  updatePerson({ commit }, person) {
    commit(UPDATE_PERSON, person);
  },
  updateFirma({ commit }, firma) {
    commit(UPDATE_FIRMA, firma);
  },
  addFirmaPapierkorb({ commit }, firma) {
    commit(ADD_FIRMA_PAPIERKORB, firma);
  },
  addFirmen({ commit }, firmen) {
    commit(ADD_FIRMEN, firmen);
  },
  addPersonPapierkorb({ commit }, person) {
    commit(ADD_PERSON_PAPIERKORB, person);
  },
  deletePersonenPapierkorb({ commit }, personen) {
    commit(DELETE_PERSONEN_PAPIERKORB, personen);
  },
  removePerson({ commit }, person) {
    commit(REMOVE_PERSON, person);
  },
  setPersonen({ commit }, obj) {
    commit(SET_PERSONEN, obj);
  },
  deleteFirma({ commit }, firmaId) {
    commit(DELETE_FIRMA, firmaId);
  },
  setfilteredPersonen({ commit }, obj) {
    commit(SET_FILTERED_PERSONEN, obj);
  },
  setfilteredPapierkorb({ commit }, obj) {
    commit(SET_FILTERED_PAPIERKORB, obj);
  },
  setAktionen({ commit }, obj) {
    commit(SET_AKTIONEN, obj);
  },
  addPersonen({ commit }, personen) {
    commit(ADD_PERSONEN, personen);
  },
  setfilteredAktionen({ commit }, obj) {
    commit(SET_FILTERED_AKTIONEN, obj);
  },
  setPraesente({ commit }, obj) {
    commit(SET_PRAESENTE, obj);
  },
  setfilteredFirmen({ commit }, obj) {
    commit(SET_FILTERED_FIRMEN, obj);
  },
  setFirmen({ commit }, obj) {
    commit(SET_FIRMEN, obj);
  },
  deleteFirmaPapierkorb({ commit }, firmen) {
    commit(DELETE_FIRMA_PAPIERKORB, firmen);
  },
  async setPerson({ commit }, obj) {
    var person = await axios.get('/api/Stammdaten/GetPerson/' + obj);
    commit(SET_PERSON, person);
  },
  async getAktionen({ commit }) {
    const data = await axios.get('/api/Stammdaten/Aktionen');
    commit(SET_AKTIONEN, data);
    commit(SET_FILTERED_AKTIONEN, data);
  },
  async getPersonen({ commit }) {
    const data = await axios.get('/api/Stammdaten/Personen');
    commit(SET_PERSONEN, data);
    commit(SET_FILTERED_PERSONEN, data);
    commit(SET_LOADING);
  },
  async getFirmen({ commit }) {
    const data = await axios.get('/api/Stammdaten/Firmen');
    commit(SET_FIRMEN, data);
    commit(SET_FILTERED_FIRMEN, data);
  },
  async getDeletedPersonen({ commit }) {
    const data = await axios.get('/api/Stammdaten/DeletedPersons');
    commit(SET_PAPIERKORB, data);
    commit(SET_FILTERED_PAPIERKORB, data);
  },

  async getDeletedFirmen({ commit }) {
    const response = await axios.get('/api/stammdaten/deletedfirmen');
    commit(SET_FIRMEN_PAPIERKORB, response.data);
  }

});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
