<template>
  <v-app v-if="profile">
    <v-navigation-drawer v-if="profile && profile.isAdmin"
                         permanent
                         expand-on-hover
                         dark
                         app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar tile>
            <img alt="elite-logo" src="/img/elite-logo.png"/>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>

      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-item v-for="item in $router.options.routes"
                     :key="item.title"
                     @click="routeTo(item.path)">

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark dense app>
      <img v-if="!profile.isAdmin" width="30" alt="elite-logo" src="/img/elite-logo.png"/>
      <v-spacer></v-spacer>
      <v-btn outlined @click="logout">
        Logout
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';

const addperson = () => import('./components/crm/add-person');
const personen = () => import('./components/crm/personen');
const firmen = () => import('./components/crm/firmen');
const aktionen = () => import('./components/crm/aktionen');
const aktionenDialog = () => import('./components/crm/add-aktion');
const aktivDialog = () => import('./components/aktiv-dialog');
const addfirma = () => import('./components/crm/add-firma');
const delete_Dialog = () => import('./components/delete-dialog');
const praesente = () => import('./components/crm/praesente');
const praesentDialog = () => import('./components/crm/add-praesent');
const logs = () => import('./components/logs');
const verwaltung = () => import('./components/verwaltung');
const loading = () => import('./components/loading-dialog');
const papierkorb = () => import('./components/crm/papierkorb');


Vue.component('add-person', addperson);
Vue.component('personen', personen);
Vue.component('firmen', firmen);
Vue.component('aktionen', aktionen);
Vue.component('add-aktion', aktionenDialog);
Vue.component('add-firma', addfirma);
Vue.component('delete-dialog', delete_Dialog);
Vue.component('praesente', praesente);
Vue.component('add-praesent', praesentDialog);
Vue.component('verwaltung', verwaltung);
Vue.component('aktiv-dialog', aktivDialog);
Vue.component('loading', loading);
Vue.component('papierkorb', papierkorb);

export default {
  components: {},

  data() {
    return {
      drawer: true,
      right: null,
      cruds: ['Personen', 'Firmen', 'Aktionen', 'Präsente'],
      autosave: false
    }
  },
  computed: {
    ...mapState(['profile'])
  },
  mounted() {
    this.$store.dispatch('setProfile');
  },
  created(){
  },
  methods: {
    ...mapActions(['setProfile']),
    logout() {
      this.$http.get('/api/users/logout').then(response => {
        // this.$router.push('/')
        this.setProfile({profile: null});
        window.location.reload();
      }).catch((error) => console.log(error));
    },
    routeTo: function (name) {
      this.$router.push(name);
    },
    isSite: function (site) {
      if (this.$route.path != site) {
        return false;
      } else {
        return true;
      }

    }

  }
}
</script>

<style scoped>
a:hover {
  text-decoration: unset !important;
  color: red !important;
}
</style>
